'use strict';

/**
 * @ngdoc function
 * @name bgAngularApp.controller:AboutCtrl
 * @description
 * # AboutCtrl
 * Controller of the bgAngularApp
 */
angular.module('bgAngularApp')
  .controller('SettingsCtrl', ['$scope', '$rootScope', '$route', function ($scope, $rootScope, $route) {
    // TODO: Just use rootscope and remove duplicate code for reading settings from storage?
    $scope.alwaysShowSmileys = localStorage.getItem('alwaysShowSmileys') != null;
    $scope.autoBBCode = localStorage.getItem('autoBBCode') != null;
    $scope.disableTitles = localStorage.getItem('disableTitles') != null;
    $scope.disableTweetCache = localStorage.getItem('disableTweetCache') != null;
    $scope.doubleTapVideos = localStorage.getItem('doubleTapVideos') != null;
    $scope.enableBanners = localStorage.getItem('enableBanners') != null;
    $scope.enableDarkMode = localStorage.getItem('enableDarkMode') != null;
    $scope.enableQuickPost = localStorage.getItem('enableQuickPost') != null;
    $scope.fetchVideoMetadata = localStorage.getItem('fetchVideoMetadata') != null;
    $scope.hideImages = localStorage.getItem('hideImages') != null;
    $scope.showUnreadBadges = localStorage.getItem('showUnreadBadges') != null;
    $scope.useYewtube = localStorage.getItem('useYewtube') != null;

    function watchSetting(setting) {
      $scope.$watch(setting, function(newVal, oldVal) {
        if(newVal != oldVal) {
          if(newVal) {
            localStorage.setItem(setting, true);
          } else {
            localStorage.removeItem(setting);
          }

          $rootScope[setting] = newVal;
        }
      });
    }

    $scope.reload = function() {
      window.location.reload(true);
    }


    watchSetting('alwaysShowSmileys');
    watchSetting('autoBBCode');
    watchSetting('disableTitles');
    watchSetting('disableTweetCache');
    watchSetting('doubleTapVideos');
    watchSetting('enableBanners');
    watchSetting('enableDarkMode');
    watchSetting('enableQuickPost');
    watchSetting('fetchVideoMetadata');
    watchSetting('hideImages');
    watchSetting('showUnreadBadges');
    watchSetting('useYewtube');
}]);
