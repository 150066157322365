'use strict';

import $ from 'jquery';

/**
 * @ngdoc function
 * @name bgAngularApp.controller:MainCtrl
 * @description
 * # MainCtrl
 * Controller of the bgAngularApp
 */
angular.module('bgAngularApp')
  .controller('PostCtrl', ['$scope', 'badgame', '$sce', '$location', '$routeParams', '$rootScope', function ($scope, badgame, $sce, $location, $routeParams, $rootScope) {
    $scope.invalidPost = false;
    $scope.postParams = {};
    $scope.message = '';
    $scope.showSmileys = false;

    function refreshIcons() {
      angular.forEach($scope.availableIcons, function (icon) {
        if (icon.value === $scope.postParams.icon) {
          icon.selected = true;
        } else {
          icon.selected = false;
        }
      });
    }

    badgame.postInit().then(function (data) {
      $scope.newPostMode = badgame.newPostMode;

      $scope.postParams = data;
      $scope.availableIcons = data.available_icons;
      $scope.showSmileys = $rootScope.alwaysShowSmileys;
      $scope.smileys = data.smileys[0].smileys
        .map(function (x) {
          return {
            code: x.code,
            url: data.smiley_url + '/' + x.filename
          };
        });

      refreshIcons();

      $scope.msgId = $routeParams.msg;

      // Disable SMF forwarding, we'll handle it
      $scope.postParams.noforward = 1;

      $scope.message = $sce.trustAsHtml(data.message);
    }, function () {
      // Something went wrong with post init, kick user back out to root view
      // This should only occur if user manually refreshes the post view
      $location.path('/');
    });


    $scope.performPost = function () {
      // Bypass angular scope stuff here, ng-model and ng-bind-html don't play nice?
      $scope.postParams.message = $('#post-input').val();

      // No blank posts
      if ($scope.postParams.message.length == 0) {
        $scope.invalidPost = true;
        return;
      }

      badgame.handlePost($scope.postParams).then(function () {
        if ($scope.newPostMode) {
          $location.path('/board/' + badgame.lastBoard);
        } else {
          // Redirect to new or to msg if specified
          if ($scope.msgId) {
            $location.hash($scope.msgId);
            $location.path('/topic/' + $scope.postParams.topic + '/' + $scope.msgId);
          } else {
            $location.hash('new');
            $location.path('/topic/' + $scope.postParams.topic + '/new');
          }
        }
      });
    };

    $scope.selectIcon = function (icon) {
      $scope.postParams.icon = icon.value;
      refreshIcons();
    };

    function pasteTagged(url, tag) {
      var msgBox = $('#post-input');
      var txt = msgBox.val();
      var pos = msgBox[0].selectionStart;

      var openTag = '[' + tag + ']';
      var closeTag = '[/' + tag + ']';
      var tagged = openTag + url + closeTag;

      msgBox.val(txt.substring(0, pos) + tagged + txt.substring(pos, txt.length));

      msgBox[0].focus();
      msgBox[0].selectionStart = pos + tagged.length;
    }

    $scope.handlePaste = function ($event) {
      if (!$rootScope.autoBBCode) return;

      var pasteData = $event.clipboardData.getData("text") || $event.clipboardData.getData("text/uri-list");

      const imgRegex = /^https?:\/\/\S+\.(jpg|png|gif)(\?\S*)?$/gi;
      if (imgRegex.test(pasteData)) {
        pasteTagged(pasteData, 'img');
        $event.preventDefault();
        return;
      }

      const webmRegex = /^https?:\/\/\S+\.(webm|mp4)(\?\S*)?$/gi;
      if (webmRegex.test(pasteData)) {
        pasteTagged(pasteData, 'webm');
        $event.preventDefault();
        return;
      }

      const ytRegex = /^(https?\:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/gi;
      if (ytRegex.test(pasteData)) {
        pasteTagged(pasteData, 'youtube');
        $event.preventDefault();
        return;
      }

      const tweetRegex = /^https?:\/\/twitter\.com\/(?:#!\/)?(\w+)\/status(es)?\/(\d+)/gi;
      if (tweetRegex.test(pasteData)) {
        pasteTagged(pasteData, 'tweet');
        $event.preventDefault();
        return;
      }
    };

  }]);

