'use strict';

import $ from 'jquery';

angular.module('bgAngularApp')
  .factory('badgame', ['$rootScope', '$http', '$log', '$q', '$sanitize', '$location', function($rootScope, $http, $log, $q, $sanitize, $location) {
    var factory = {};

    var URL_DOMAIN = 'badgame.net';
    var URL_BASE = 'https://' + URL_DOMAIN + '/';

    var LOGIN_URL = URL_BASE + 'index.php?action=login2;json';
    var BOARD_URL = URL_BASE + 'index.php?json&board=';
    var CATEGORY_URL = URL_BASE + '?json';
    var FINDER_URL = URL_BASE + 'index.php?action=findthread';
    var TOPIC_URL = URL_BASE + 'index.php?json&topic=';
    var SEARCH_URL = URL_BASE + 'index.php?json&action=solrresults';
    var TOKEN_URL = URL_BASE + 'index.php?json&action=token';

    // Access token for 3rd party services
    factory.accessToken = '';

    // What topic are we currently viewing?
    factory.currentTopic = 0;

    // Store out path for posting (New Post, Quote, Reply)
    factory.postUrl = '';

    // Maintain search params in service
    factory.searchParams = {};

    // Who am I?
    factory.userId = 0;
    factory.session = '';

    factory.avatar = '';
    factory.username = '';

    factory.bannerQueue = [];

    factory.popBanner = function() {
      if (factory.bannerQueue.length) {
        var next = factory.bannerQueue.pop();
        $rootScope.bannerSrc = next.src;
      }
    }

    function updateBanner(data) {
      $('.banner').first().removeClass('no-animate');

      if (data.banner_src) {
        var bannerHistory = localStorage.getItem('banner-history');

        if (bannerHistory) {
          bannerHistory = JSON.parse(bannerHistory);
        } else {
          bannerHistory = [];
        }

        factory.bannerQueue = bannerHistory.slice();

        bannerHistory.push({
          'src': data.banner_src
        });

        bannerHistory = bannerHistory.slice(-100);
        localStorage.setItem('banner-history', JSON.stringify(bannerHistory));


        $rootScope.bannerSrc = data.banner_src;
      }
    }


    /*
      Find threads by matching the title of the given query
    */
    factory.findThreads = function(query) {
      var deferred = $q.defer();

      $http({
        method: 'GET',
        url: FINDER_URL + '&q=' + query,
        withCredentials: true
      }).then(function(resp) {
        deferred.resolve(resp.data);
      }, function() {
        deferred.reject();
      });

      return deferred.promise;
    };

    /*
      Get available forums to post in
    */
    factory.getBoards = function () {
      var deferred = $q.defer();

      $http({
        method: 'GET',
        url: CATEGORY_URL,
        withCredentials: true
      }).then(function(resp) {
        updateBanner(resp.data);
        deferred.resolve(resp.data);
      }, function() {
        deferred.reject();
      });

      return deferred.promise;
    };

    /*
      Get topics for a specified forum
    */
    factory.getTopics = function (board_id, offset) {
      var offset = offset || 0;
      var deferred = $q.defer();

      $http({
        method: 'GET',
        url: BOARD_URL + board_id + '.' + offset,
        withCredentials: true
      }).then(function(resp) {
        updateBanner(resp.data);
        deferred.resolve(resp.data);
      }, function () {
        deferred.reject();
      });

      return deferred.promise;
    };

    /*
      Get the posts for a specified topic
    */
    factory.getPosts = function (offset) {
      var offset = offset || 0;
      var deferred = $q.defer();

      $http({
        method: 'GET',
        url: TOPIC_URL + factory.currentTopic + '.' + offset,
        withCredentials: true
      }).then(function(resp) {
        updateBanner(resp.data);
        factory.avatar = resp.data.av_url;
        factory.username = resp.data.username;
        deferred.resolve(resp.data);
      }, function () {
        deferred.reject()
      });

      return deferred.promise;
    };

    /*
      Get the posts for a search query
    */
    factory.getSearchResults = function(offset) {
      var offset = offset || 0;
      var deferred = $q.defer();

      $http({
        data: $.param({search: factory.searchParams.search}),
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        method: 'POST',
        url: SEARCH_URL + '&start=' + offset,
        withCredentials: true
      }).then(function(resp) {
        deferred.resolve(resp.data);
      }, function() {
        deferred.reject();
      });

      return deferred.promise;
    };

    factory.login = function(user, pass) {
      var deferred = $q.defer();

      var data = {
        user: user,
        passwrd: pass,
        cookieneverexp: 1
      };

      $http({
        method: 'POST',
        url: LOGIN_URL,
        data: $.param(data),
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        withCredentials: true
      }).then(function() {
        deferred.resolve();
      }, function() {
        deferred.reject();
      });

      return deferred.promise;
    };

    factory.postInit = function(skipBanner) {
      var deferred = $q.defer();

      // TODO: Maintain postUrl in a smarter way? Currently a user can't refresh the posting
      // page without it breaking.
      if(factory.postUrl.length === 0) {
        deferred.reject();
      }

      // Force HTTPS if not specified
      // TODO: Figure out a better way of running dev on local/remote instances.  Remove this bit if you need to test against local without https.
      if (!factory.postUrl.startsWith('http')) {
        factory.postUrl = 'https:' + factory.postUrl;
      }

      $http({
        method: 'GET',
        url: factory.postUrl,
        withCredentials: true
      }).then(function(resp) {
        if (!skipBanner) {
          updateBanner(resp.data);
        }

        deferred.resolve(resp.data);
      }, function() {
        deferred.reject();
      });

      return deferred.promise;
    };

    factory.handlePost = function(data) {
      var deferred = $q.defer();

      // TODO: Another weird URL to figure out when developing without https
      if (!data.form_action.startsWith('http')) {
        data.form_action = 'https:' + data.form_action;
      }

      // Sanitize fields known for issues in badgame
      var textFields = ["subject", "message", "guestname", "evtitle", "question"];
      angular.forEach(textFields, function(value, key) {
        data[value]= data[value] || '';
        data[value] = $sanitize(data[value]);

        // Allow some characters converted by sanitize, SMF expects them
        /*
          A whitelist would be nicer but weird to do without it affecting
          everything that uses sanitize in app.
        */
        data[value] = data[value].replace(/&#10;/g, "\n");
        data[value] = data[value].replace(/&amp;/g, "&");
        data[value] = data[value].replace(/&lt;/g, "<");
        data[value] = data[value].replace(/&gt;/g, ">");
      });

      var fd = new FormData();
      for(var key in data) {
        fd.append(key, data[key]);
      }

      $http({
        method: 'POST',
        url: data.form_action,
        data: fd,
        headers: {'Content-Type': undefined},
        transformRequest: angular.identity,
        withCredentials: true
      }).then(function(resp) {
        deferred.resolve();
      }, function() {
        deferred.reject();
      });

      return deferred.promise;
    };

    factory.requestToken = function() {
      var deferred = $q.defer();

      $http({
        method: 'GET',
        url: TOKEN_URL,
        withCredentials: true
      }).then(function(resp) {
        factory.accessToken = resp.data['token'];
        factory.session = resp.data['session'];
        factory.userId = resp.data['member'];

        deferred.resolve();
      }, function() {
        deferred.reject();
      });

      return deferred.promise;
    };

    return factory;
}]);
