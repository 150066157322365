'use strict';

angular.module('bgAngularApp')
  .directive('toastPost', ['$rootScope', '$timeout', '$location', '$route', 'badgame', function($rootScope, $timeout, $location, $route, badgame) {
    return {
      restict: 'E',
      link: function(scope, element, attr) {
        scope.activated = false;
        scope.body = '';

        scope.handleReply = function () {
          badgame.postUrl = scope.replyUrl;

          badgame.postInit().then( function(data) {
            var params = data;
            params.message = scope.body;
            params.noforward = 1;

            badgame.handlePost(params).then( function() {
              $location.hash('new');
              $location.path('/topic/' + params.topic + '/new');
              $route.reload();
            }, function() {
              console.log('it didnt work :(');
            });
          });
        };

        $rootScope.$on('toast-post-trigger', function() {
          scope.activated = !scope.activated;
        });

      },
      template: require('../../views/templates/toast-post.html').default
    };
}]);
