'use strict';

import $ from 'jquery';

angular.module('bgAngularApp')
  .directive('banner', ['badgame', function(badgame) {
    return {
      restict: 'A',
      link: function(scope, element, attr) {
        scope.popBanner = function() {
          element.addClass('no-animate');
          badgame.popBanner();
        };
      },
    };
}]);
