'use strict';

/**
 * @ngdoc function
 * @name bgAngularApp.controller:MainCtrl
 * @description
 * # MainCtrl
 * Controller of the bgAngularApp
 */
angular.module('bgAngularApp')
  .controller('BoardCtrl', ['$scope', 'badgame' , 'Page', '$route', '$routeParams', '$location', '$anchorScroll', function ($scope, badgame, Page, $route, $routeParams, $location, $anchorScroll) {
    $scope.topicsPerPage = 20;

    function refreshTopics() {
      var offset = $routeParams.offset || 0;
      badgame.getTopics($routeParams.boardId, offset).then(function(data) {
        Page.setTitle('Badgame Lite');
        badgame.postUrl = 'https://badgame.net/index.php?action=post;board=' + $routeParams.boardId + '.0;json=1';
        badgame.newPostMode = true;
        badgame.lastBoard = $routeParams.boardId;

        $scope.topics = data.topics;
        $scope.currentPage = data.page_info.current_page;
        $scope.topicsPerPage = data.page_info.items_per_page;
        $scope.totalItems = data.page_info.total_items;
      });
    };

    // Monitor rendering status and jump to anchor
    $scope.$on('ngRepeatFinished', function(event) {
      $anchorScroll('top');
    });

    $scope.$on('routeUpdate', function(event) {
      refreshTopics();
    });

    $scope.pageChanged = function() {
      $route.updateParams({
        boardId: $routeParams.boardId,
        offset: ($scope.currentPage - 1) * $scope.topicsPerPage
      })
      refreshTopics();
    };

    // Initial load of topics
    refreshTopics();
  }]);
