'use strict';

angular.module('bgAngularApp')
  .directive('breadCrumb', [function() {
    return {
      restict: 'E',
      link: function(scope, element, attr) {
      },
      template: require('../../views/templates/bread-crumb.html').default
    };
}]);
