import angular from 'angular';

// Got dependencies? Add them here.
import 'angular-animate';
import 'angular-cookies';
import 'angular-resource';
import 'angular-route';
import 'angular-sanitize';
import 'angular-touch';
import 'angular-ui-bootstrap';
import 'angular-toastr';


/**
 * @ngdoc overview
 * @name bgAngularApp
 * @description
 * # bgAngularApp
 *
 * Main module of the application.
 */
export default angular
  .module('bgAngularApp', [
    'ngAnimate',
    'ngCookies',
    'ngResource',
    'ngRoute',
    'ngSanitize',
    'ngTouch',
    'ui.bootstrap',
    'toastr'
  ])
  .run(['$rootScope', '$route', 'Page', '$window', function($rootScope, $route, page, $window) {
    // Fix for html generated for img tags
    window.Badgame = {
      prepareImage: function(){}
    };

    $rootScope.Page = page;

    // Read settings
    $rootScope.disableTitles = localStorage.getItem('disableTitles') != null;
    $rootScope.disableTweetCache = localStorage.getItem('disableTweetCache') != null;
    $rootScope.enableBanners = localStorage.getItem('enableBanners') != null;
    $rootScope.enableDarkMode = localStorage.getItem('enableDarkMode') != null;
    $rootScope.enableQuickPost = localStorage.getItem('enableQuickPost') != null;
    $rootScope.fetchVideoMetadata = localStorage.getItem('fetchVideoMetadata') != null;
    $rootScope.hideImages = localStorage.getItem('hideImages') != null;
    $rootScope.autoBBCode = localStorage.getItem('autoBBCode') != null;
    $rootScope.alwaysShowSmileys = localStorage.getItem('alwaysShowSmileys') != null;
    $rootScope.showUnreadBadges = localStorage.getItem('showUnreadBadges') != null;
    $rootScope.doubleTapVideos = localStorage.getItem('doubleTapVideos') != null;
    $rootScope.useYewtube = localStorage.getItem('useYewtube') != null;

    // Detect if window is focused or not
    angular.element($window).bind('focus', function() {
      page.setBadge('');
      $rootScope.$apply();
    });
  }])
  .config(['$routeProvider', 'toastrConfig', function ($routeProvider, toastrConfig) {
    angular.extend(toastrConfig, {
      allowHtml: true,
      maxOpened: 1,
      preventOpenDuplicates: true
    });

    $routeProvider
      .when('/categories', {
        template: require('../views/main.html').default,
        controller: 'MainCtrl'
      })
      .when('/board/:boardId/:offset?', {
        reloadOnSearch: false,
        template: require('../views/board.html').default,
        controller: 'BoardCtrl'
      })
      .when('/topic/:topicId/:offset?', {
        reloadOnSearch: false,
        template: require('../views/topic.html').default,
        controller: 'TopicCtrl'
      })
      .when('/post/:msg?', {
        template: require('../views/post.html').default,
        controller: 'PostCtrl'
      })
      .when('/search', {
        template: require('../views/search.html').default,
        controller: 'SearchCtrl'
      })
      .when('/settings', {
        template: require('../views/settings.html').default,
        controller: 'SettingsCtrl'
      })
      .when('/searchresults/:offset?', {
        reloadOnSearch: false,
        template: require('../views/topic.html').default,
        controller: 'TopicCtrl'
      })
      .when('/firehose/:boardId', {
        reloadOnSearch: false,
        template: require('../views/topic.html').default,
        controller: 'TopicCtrl'
      })
      .otherwise({
        redirectTo: '/categories'
      });
  }]);


